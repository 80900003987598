body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-no-repeat bg-center bg-cover bg-fixed bg-black bg-[url('/public/bg.png')];
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-connect {
  @apply font-bold text-xl text-white bg-gradient-to-r from-custom-purple1 to-custom-blue1 rounded border-opacity-0 focus:border-opacity-0 ;
}

.btn-connect:hover {
  @apply from-custom-purple1/80 to-custom-blue1/80 border-opacity-0;
}

.btn-close {
  @apply bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full border-none;
}

.btn-close:hover {
  @apply shadow-lg bg-red-600;
}

.dropdown .dropdown-content {
  display: none;
}
.dropdown .dropdown-content.show {
  visibility: visible;
  opacity: 1;
  display: block;
}

.fix-h-screen {
  padding-bottom: 10rem;
}

.text-ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 280px) {
  .estimated.tooltip:before {
    max-width: 6.5rem;
  }
  .estimated-confirm.tooltip:before{
    max-width: 13rem!important;
    transform: translateX(-35%)!important;
  }
}

@media (min-width: 281px) and (max-width: 360px) {
  .estimated.tooltip:before {
    max-width: 8rem;
  }
  .estimated-confirm.tooltip:before{
    max-width: 13rem!important;
    transform: translateX(-25%)!important;
  }
}

@media (min-width: 361px) and (max-width: 480px) {
  .estimated.tooltip:before {
    max-width: 11rem;
  }
  .estimated-confirm.tooltip:before{
    max-width: 15rem!important;
    transform: translateX(-20%)!important;
  }
}

@media (min-width: 481px) and (max-width: 897px) {
  .estimated.tooltip:before {
    max-width: 18rem;
  }
  .estimated-confirm.tooltip:before{
    max-width: 25rem!important;
    transform: translateX(-30%)!important;
  }
}

.estimated.tooltip:before {
  z-index: 999;
  position: absolute!important;
  transform: translateY(-10%)!important;
}

.estimated-confirm.tooltip:before{
  max-width: 25rem;
  transform: translateX(-30%)!important;
}
